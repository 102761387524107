import { hasTrailingSlash, withoutTrailingSlash } from 'ufo'

export default defineNuxtRouteMiddleware(async (to) => {
  if (
    // If one of these named routes (do not include the spec page here)
    (to.name === 'page_slug' || to.name === 'apis-api_slug' || to.name === 'apis-api_slug-docs') &&
    // And the path has a trailing slash
    hasTrailingSlash(to.path) &&
    // !Important: And the path is not the root path
    to.path !== '/'
  ) {
    // Redirect to the same path without the trailing slash
    // Keep the query and hash
    return navigateTo({
      path: withoutTrailingSlash(to.path),
      query: to.query,
      hash: to.hash,
    }, {
      redirectCode: 301,
      replace: true,
    })
  }
})
